<template>
    <div>
        <card-slides :contents="homepage.featured" />
        <promo />
        <div class="flex gap-4">
            <div class="flex-1 grid grid-cols-12 gap-4 items-center my-6">
                <div class="col-span-12 lg:col-span-4">
                    <Link href="/pages/apps" class=" no-underline" target="_blank"><img
                        src="https://cdn.nomadlyrics.com/static/artists/UJFpkk4dV0Xes1R6TSPcJzxcG7OyQkLZDc1kPuHY.png"
                        alt="Download App" class="rounded-lg"></Link>
                </div>
                <div class="col-span-12 lg:col-span-6 flex flex-col gap-3">
                    <div class=" uppercase text-nomad-accent font-thin">APPS</div>
                    <h2 class="text-white font-bold text-2xl">New iPhone & Android apps</h2>
                    <p class="text-xl font-thin text-nomad-200">
                        Discover the heart of Somali culture with Nomad Lyrics! Your go-to app for music, podcasts, news, events, and more.
                    </p>
                    <div class="flex"><Link href="/pages/apps" target="_blank"
                            class="text-nomad-accent   border rounded-xl font-thin hover:bg-nomad-accent hover:text-nomad-900 p-2 inl ">Read more</Link></div>
                </div>
            </div>
            <div class="flex-1 grid grid-cols-12 gap-4 items-center my-6">
                <div class="col-span-12 lg:col-span-4"><a href="https://mrembo.co" class=" no-underline"
                        target="_blank"><img
                            src="https://mrembo.co/cdn/shop/articles/0a1655fae72fce07b8e3705dcfd964c1-xxlarge.jpg?v=1705127791&width=2048"
                            alt="Mrembo" class="rounded-lg"></a></div>
                <div class="col-span-12 lg:col-span-6 flex flex-col gap-3">
                    <div class=" uppercase text-nomad-accent font-thin">Mrembo Skin Care</div>
                    <h2 class="text-white font-bold text-2xl">Discover The Natural Beauty Secret</h2>
                    <p class="text-xl font-thin text-nomad-200">
                        Harness the Power of Qasil for Clear, Nourished, and Radiant Skin – Unveil Your True Beauty.
                        Pre-order
                        now, 100% Somali owned business.
                    </p>
                    <div class="flex"><a href="https://mrembo.co" target="_blank"
                            class="text-nomad-accent   border rounded-xl font-thin hover:bg-nomad-accent hover:text-nomad-900 p-2 inl ">Learn more</a></div>
                </div>
            </div>
        </div>

        <trending :songs="homepage.trending" />
        <newsletter />
        <latest-podcasts :podcasts="homepage.episodes" />
        <events-slides :contents="homepage.events" v-if="homepage.events && homepage.events.length" />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <popular-artists :artists="homepage.trendingArtists" />
            <popular-songs :songs="homepage.currentWeek" />
        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import CardSlides from "@/components/CardSlides.vue";
import EventsSlides from "@/components/EventsSlides.vue";
import PopularSongs from "@/components/PopularSongs.vue";
import PopularArtists from "@/components/PopularArtists.vue";
import Trending from "@/components/Trending.vue";
import { Link, usePage } from "@inertiajs/vue3";
import { mapState, mapActions, } from "vuex";
import LatestPodcasts from "@/components/LatestPodcasts.vue";

export default {
    provide() {
        return {
            stream: this.$root.$data,
        };
    },
    beforeMount() {
        document.title = 'Nomad Lyrics - Somali Music Lyrics & More';
    },
    mounted() {
        this.prepareHomepage();
    },
    computed: {
        ...mapState({
            homepage: (state) => state.homepage.all,
        }),
    },
    methods: {
        ...mapActions("homepage", ["prepareHomepage"]),
    },
    components: {
        "card-slides": CardSlides,
        "events-slides": EventsSlides,
        "popular-songs": PopularSongs,
        "popular-artists": PopularArtists,
        "trending": Trending,
        'latest-podcasts': LatestPodcasts,
        'adsense' : defineAsyncComponent(() => import("@/components/Adsense.vue")),
        Link,
        newsletter: defineAsyncComponent(() => import('@/components/Newsletter')),
        AdsenseResponsive: defineAsyncComponent(() => import('@/components/AdsenseResponsive')),
        Promo: defineAsyncComponent(() => import('@/components/Promo'))
    },
};
</script>
